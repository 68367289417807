import './App.css';
import NavBar from './Components/NavBar/NavBar';
import AppRouter from './Components/AppRouter/AppRouter';


function App() {
  return (
    <div className="App">
      <NavBar/>
      <AppRouter/>
    </div>
  );
}

export default App;
