import React from 'react';
import './MyProjects.css'
const MyProjects = () => {
    return (
        <div className="MyProjects">
           <h1>My projects</h1> 
        </div>
    );
};

export default MyProjects;