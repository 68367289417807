import React from 'react';
import NeonButton from '../NeonButton/NeonButton';
import './Home.scss';
import { LinkContainer } from 'react-router-bootstrap';

const Home = () => {
	const hardSkillsArr = [
		{ label: 'Javascript ES6', url: 'https://www.w3schools.com/js/js_es6.asp' },
		{ label: 'Typescript', url: 'https://www.typescriptlang.org/' },
		{ label: 'React', url: 'https://reactjs.org/' },
		{ label: 'Next', url: 'https://nextjs.org/docs/getting-started' },
		{ label: 'Vue', url: 'https://vuejs.org/guide/introduction.html' },
		{ label: 'Web components', url: 'https://lit.dev/' },
		{
			label: 'Html5',
			url: 'https://developer.mozilla.org/en-US/docs/Glossary/HTML5',
		},
		{ label: 'Css', url: 'https://developer.mozilla.org/es/docs/Web/CSS' },
		{ label: 'Bootstrap', url: 'https://getbootstrap.com/' },
		{ label: 'Node', url: 'https://nodejs.org/en/docs/' },
		{ label: 'REST API', url: '#' },
		{ label: 'Docker', url: 'https://www.docker.com/' },
		{ label: 'Version control tools', url: 'https://git-scm.com/doc' },
		{ label: 'Documentation tools', url: 'https://astro.build/' },
	];
	const softSkillsArr = [
		'Team work',
		'Organization',
		'Autonomy',
		'Adaptability',
		'Empathy',
		'Flexibility',
		'Commitment',
		'Quick learnign',
		'Open to learn',
	];
	const projects = [
		{
			label: 'PWA-counter',
			url: 'https://github.com/aliparra/pwa-counter',
			imgUrl:
				'https://res.cloudinary.com/dv4pkbmin/image/upload/v1672146975/personal-web/pwa-counter_x69h3l.png',
			tecnologies: ['LitElement', 'Open Web Components'],
		},
		{
			label: 'Basic CRUD',
			url: 'https://github.com/aliparra/Basic-crud-front',
			imgUrl:
				'https://res.cloudinary.com/dv4pkbmin/image/upload/v1672147323/personal-web/react-crud_uosmhe.png',
			tecnologies: ['React'],
		},
		{
			label: 'Basic CRUD API',
			url: 'https://github.com/aliparra/Basic-crud-api',
			imgUrl:
				'https://res.cloudinary.com/dv4pkbmin/image/upload/v1672147323/personal-web/react-crud_uosmhe.png',
			tecnologies: ['Node', 'Express', 'Mongodb', 'Mongoose'],
		},
		{
			label: 'Winter wonderland game',
			url: 'https://aliparra.github.io/Winterland-M1project/WINTERLAND-GAME/',
			imgUrl:
				'https://res.cloudinary.com/dv4pkbmin/image/upload/v1622230901/personal-web/winterland_1_hz30mf.png',
			tecnologies: ['Html', 'css', 'Javascript'],
		},
		{
			label: 'Vitae Web',
			url: 'https://github.com/CodeCheeks/Vitae-Web',
			imgUrl:
				'https://res.cloudinary.com/dv4pkbmin/image/upload/v1622230885/personal-web/vitae_oymhvu.png',
			tecnologies: [
				'NodeJs',
				'ExpressJs',
				'MongoDb',
				'Mongoose',
				'JS',
				'Nodemailer',
				'Cloudinary',
				'Postman',
				'CORS',
			],
		},
		{
			label: 'Vitae Professionals',
			url: 'https://github.com/CodeCheeks/Vitae-Professionals',
			imgUrl:
				'https://res.cloudinary.com/dv4pkbmin/image/upload/v1622230882/personal-web/professionals_mctilv.png',
			tecnologies: [
				'ReactJS',
				'NodeJs',
				'ExpressJs',
				'MongoDB',
				'Mongoose',
				'JS',
				'HTML',
				'Css',
				'Boostrap',
				'Nodemailer',
				'JWT',
				'NPM',
			],
		},
		{
			label: 'Vitae API',
			url: 'https://github.com/CodeCheeks/Vitae-API',
			imgUrl:
				'https://res.cloudinary.com/dv4pkbmin/image/upload/v1622230896/personal-web/vitaeapi_y9gvzc.png',
			tecnologies: [
				'ReactJS',
				'NodeJs',
				'ExpressJs',
				'MongoDB',
				'Mongoose',
				'JS',
				'HTML',
				'Css',
				'Boostrap',
				'Nodemailer',
				'JWT',
				'NPM',
			],
		},
	];
	return (
		<div className="Home">
			<div className="container-fluid">
				<div className="row my-5 align-items-center justify-content-center">
					<div className="col">
						<div className="row justify-content-center">
							<img
								src="https://res.cloudinary.com/dv4pkbmin/image/upload/v1622031111/personal-web/avataaars_21_wc68q4.png"
								alt="logo"
								width="300px"
							/>
						</div>
					</div>
					<div className="col text__animation">
						<div className="row justify-content-start">
							<div className="col-12 d-flex flex-column align-content-center">
								<h2 className="sign__word big__size text-center my-3">
									Alicia Parra
								</h2>
								<h1 className="glow__change text-center mb-3">
									Front-end developer
								</h1>
							</div>
							<div className="col-12 d-flex justify-content-center">
								<LinkContainer to="/contact">
									<NeonButton
										text={'Contact me'}
										direction={'/contact'}
										className="text-center"
									/>
								</LinkContainer>
								<a href="https://www.linkedin.com/in/aliciaparra1/">
									<img
										src="https://res.cloudinary.com/dv4pkbmin/image/upload/v1622397216/Icon/linkedin_1_slmfhe.png"
										alt="Linkedin"
										width="30"
										className="inverted ml-4 mt-2 mx-2 d-md-none"
									/>
								</a>
								<a href="https://github.com/aliparra">
									<img
										src="https://res.cloudinary.com/dv4pkbmin/image/upload/v1622397215/Icon/github_1_qac6yl.png"
										alt="Github"
										width="30"
										className="inverted mt-2 mx-2 d-md-none"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div
					className="row my-5 py-5 light__overlie justify-content-center align-items-center"
					name="about"
				>
					<div className="col-sm-12 col-md-6">
						<h3 className="glow__yellow med__size text-center">About me</h3>
						<h5 className="white">
							Hi, I'm alicia, front end developer. I started my career as a
							developer in 2020, and since then I have participated in several
							projects learning something new every day. I like to develop
							well-structured, organised and documented projects, as well as
							working in multidisciplinary teams.{' '}
						</h5>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className=" skills__wrapper">
							<h3 className="text-center glow__blue mb-3">Hard skills</h3>
							<div className="row">
								{hardSkillsArr.map((element, i) => {
									return (
										<div className="col-4 white text-center" key={i}>
											<a href={element.url} target="_blank" rel="noreferrer" >
												<p className="dyn__element">{element.label}</p>
											</a>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div className="row">
						<div className=" skills__wrapper">
							<h3 className="text-center glow__blue mb-3">Soft skills</h3>
							<div className="row">
								{softSkillsArr.map((element, i) => {
									return (
										<div className="col-4 white text-center" key={i}>
											<p className="dyn__element" >{element}</p>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
				<div className="row my-5 py-5 light__overlie justify-content-center align-items-center">
					<div className="col med__size text-center">
						<h1 className="mb-5 glow__yellow ">My projects</h1>

						<div className="row">
							{projects.map((project, i) => {
								const { url, imgUrl, label, tecnologies } = project;
								return (
									<a
										href={url}
										target="_blank"
										rel="noreferrer"
										className="col-lg-4 col-md-6 col-sm-12"
                    key={i}
									>
										<img
											src={imgUrl}
											alt=""
											className="mx-3 my-3 interactive__item glow__border"
										/>
										<div className="project__text__wrapper">
											<p className="medium__text">{label}</p>
											<div className="tech__wrapper">
												{tecnologies.map((tec, i) => {
													return <p className="small__text" key={i}>{tec}</p>;
												})}
											</div>
										</div>
									</a>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
