import React from 'react';
import './AboutMe.css'

const AboutMe = () => {
    return (
        <div className="AboutMe">
            <h1>About me</h1>
        </div>
    );
};

export default AboutMe;