import React from 'react';
import './NotFound.css';

const NotFound = () => {
	return (
		<div className="not__found__wrapper">
			<h1>Ops! Something went wrong. Page not found</h1>
			<h2>
				Go back <a href="/">Home</a>{' '}
			</h2>
		</div>
	);
};

export default NotFound;
