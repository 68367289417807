import React from 'react';
import './NeonButton.css'
import {LinkContainer} from 'react-router-bootstrap'

const NeonButton = (props) => {
    return (
        <div className="NeonButton">
           <div className="wrapper"> 
            <LinkContainer to={props.direction}>
                    <button className="neons">{props.text}</button>
                </LinkContainer>
            </div>      
        </div>
    );
};

export default NeonButton;