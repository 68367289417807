import './NavBar.css'
import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap'

const NavBar = () => {
    return (
        <div className='NavBar'>
            <Navbar expand="lg">
                <LinkContainer to="/">
                    <Navbar.Brand className="pl-5 color__ov nav__item">Home</Navbar.Brand>
                </LinkContainer> 
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto pr-5">
                    {/* <LinkContainer to="/about">
                        <Nav.Link className=" color__ov nav__item px-5" >About me</Nav.Link>
                    </LinkContainer> 
                    <LinkContainer to="/about">
                        <Nav.Link className=" color__ov nav__item px-5" >My skills</Nav.Link>
                    </LinkContainer>   
                    <LinkContainer to="/projects">
                        <Nav.Link className=" color__ov nav__item px-5">My Projects</Nav.Link>
                    </LinkContainer> */}
                    <LinkContainer to="/contact">
                        <Nav.Link className=" color__ov nav__item px-5">Contact</Nav.Link>
                    </LinkContainer>

                    <a href='https://www.linkedin.com/in/aliciaparra1/'>
                        <img src="https://res.cloudinary.com/dv4pkbmin/image/upload/v1622397216/Icon/linkedin_1_slmfhe.png" alt="Linkedin" width="30"  className="inverted ml-4 mt-2 mx-2 d-none d-md-block"/>
                    </a>
                    <a href='https://github.com/aliparra'>
                        <img src="https://res.cloudinary.com/dv4pkbmin/image/upload/v1622397215/Icon/github_1_qac6yl.png" alt="Github"  width="30"   className="inverted mt-2 mx-2 d-none d-md-block" />
                    </a>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default NavBar;