//Packages
import React from 'react'
import { Route, Switch } from 'react-router-dom'

//Views
import Home from '../Home/Home'
import AboutMe from '../AboutMe/AboutMe'
import MyProjects from '../MyProjects/MyProjects'
import NotFound from '../NotFound/NotFound'
import Contact from '../Contact/Contact'


const AppRouter = () => {
    return (
        <div className='AppRouter'>
            <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/about' component={AboutMe} />
                <Route exact path='/projects' component={MyProjects} />
                <Route exact path='/contact' component={Contact} />
                <Route component={NotFound} />
            </Switch>
        </div>
    );
};

export default AppRouter;