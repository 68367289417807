import React from 'react';
import './Contact.css';

const Contact = () => {
	return (
		<div className="Contact container">
			<div className="contact__wrapper">
				<h1 className="text-center glow__pink">Contact me on:</h1>
				<div className="contact__links">
					<a href="https://www.linkedin.com/in/aliciaparra1/">
						<img
							src="https://res.cloudinary.com/dv4pkbmin/image/upload/v1622397216/Icon/linkedin_1_slmfhe.png"
							alt="Linkedin"
							width="60"
							height="60"
							className="inverted ml-4"
						/>
					</a>
					<a href="https://github.com/aliparra">
						<img
							src="https://res.cloudinary.com/dv4pkbmin/image/upload/v1622397215/Icon/github_1_qac6yl.png"
							alt="Github"
							width="60"
							height="60"
							className="inverted ml-4"
						/>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Contact;
